export class VehicleList {
    dateTo: string;
    dateFrom: string;
    issuePurpose: ChoiceList;
    issueType: ChoiceList;
    assignee: personDetails;
    driver: personDetails;
    vehicle: Vehicles;
    assignmentId: number;
}

export interface ChoiceList {
    label: any;
    shortCode: any;
} 

export class Vehicles {
    trimCode: string;
    regNum: string;
    model: string;
    label: string;
    type: string;
    vehicleId: number;
    make: string;
    vehicleModel:string;
    seatingCapacity: number;
}

export class personDetails {
    personnelNumber: string;
    name: string;
}

