import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { VehicleList, VehicleListConfig } from "app/flm/vehicle/vehicle-assignment/_models";

@Injectable()
export class VehicleAssignSharedService {
    public vehicleAssign: VehicleList[];
    public list: number[] = [];
    public count: number;
    public ownership;
    public vehicleListConfig: VehicleListConfig = new VehicleListConfig();
    private emitChangeSource = new Subject<any>();
    public selectedPage = 1;
    changeEmitted$ = this.emitChangeSource.asObservable();

    init() {
        this.list = [];
        this.vehicleListConfig = new VehicleListConfig();
    }
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    setVehicleData(data) {
        this.vehicleAssign = data.vehicleAssign;
        this.count = data.count;
        this.list = [];

        for (var key in this.vehicleAssign) {

            this.list.push(this.vehicleAssign[key].vehicle.vehicleId);
        }
        this.emitChange({ totalVehicles: data.count });
        this.emitChange({ dataOwnership: true });
    }

    // setPrimaryListing(data) {
    //     this.vehicleAssign = data.vehicleAssign;
    //     this.count = data.count;
    //     this.list = [];
    //     for (var key in this.vehicleAssign) {

    //         this.list.push(this.vehicleAssign[key].vehicle.vehicleId);
    //     }


    //     this.emitChange({ totalVehicles: data.count });
    // }

    clear() {
        delete this.vehicleAssign;
        delete this.count;
        delete this.list;
        delete this.vehicleListConfig;
    }

}