import { ChoiceList } from "app/hcm/persons/persons/_models";

export interface ListPayroll {
  documentNo: string;
  jobTitle: string;
  status: ChoiceList;
  payPeriod: ChoiceList;
  payRunId: number;
  facility: ChoiceList;
  person: ChoiceListPerson;
}

export class ChoiceListPerson {
  value2: string;
  value3: string;
  value: string;
}

export class PayRollDetails {
  paySlipDate: any;
  payPeriod: string;
  paymentMethod: ChoiceList;
  payRunBy: ChoiceListPerson;
  payDate: any;
  payRunTimestamp: string;
}

export class Payroll {
  bankAccount: string;
  payRollDetails: PayRollDetails;
  documentDetails: DocDetails;
  processInstncId: string;
  person: ChoiceListPerson;
  jobTitle: string;
  isLocked: boolean;
  description: string;
  payRunId: number;
  facility: ChoiceList;
  status: string;
  token: string;
}


export class PaySlip {
  elementId: string;
  valueBasis: any;
  formatValue: any;
  person: ChoiceListPerson;
  elementClassification: string;
  actualValue: string;
   actualValue2: string;
  payRunId: string;
  facility: ChoiceList;
  payRunElementId: number;
  elementName: string;
  fontweight :string;
  isLocked: boolean;
  // allowManualEntry: boolean;

} 

export class UpdatedPayRoll {

  payRunId: number;
  description:string;
 
}

export class UpdatedPaySlip {
  payRunElementId: string;
  actualValue: string;
  actualValue2: string;
  payRunId: string;
  // allowManualEntry: boolean;
  isLocked: boolean;
}



export class DocDetails {
  documentNo: string;
  revisionNo: string;
  status: ChoiceList;

}

export interface ChoiceListNumberControlled {
  value: any;
  label: any;
  numberControlled: any
}

export interface ChoiceList {
  value: any;
  label: any;
}




